import React, {Component} from "react";
import * as PropTypes from "prop-types";
// import "./Suggestion.scss";

export default function Suggestion(props) {

	const formatAutocompleteSuggestion = (suggestion) => {
		const addressText = suggestion.address_text ? `${suggestion.address_text} ` : "";
		const street = suggestion.street_line ? `${suggestion.street_line} ` : "";
		const secondary = suggestion.secondary ? `${suggestion.secondary} ` : "";
		const entries = suggestion.entries > 1 ? `(${suggestion.entries} more entries) ` : "";
		const city = suggestion.city ? `${suggestion.city} ` : "";
		const state = suggestion.state ? `${suggestion.state}, ` : "";
		const zip = suggestion.zipcode ? `${suggestion.zipcode}` : "";

		return addressText + street + secondary + entries + city + state + zip;
	}

		return (
			<div
				style={{borderBottom: '1px solid grey', padding: 5, textWarp: 'no-wrap', overflowY: 'auto', cursor: "pointer"}}
				onClick={props.selectSuggestion}
				>
				{formatAutocompleteSuggestion(props.suggestion)}
			</div>
		);
}