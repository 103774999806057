import React from "react";
import Suggestion from "./Suggestion";

let data = [
	{
		"street_line": "PO Box 0",
		"secondary": "",
		"city": "Burnt Ranch",
		"state": "CA",
		"zipcode": "95527",
		"entries": 0
	},
	{
		"street_line": "PO Box 0",
		"secondary": "",
		"city": "Burnt Ranch",
		"state": "CA",
		"zipcode": "95527",
		"entries": 0
	},
	{
		"street_line": "PO Box 0",
		"secondary": "",
		"city": "Burnt Ranch",
		"state": "CA",
		"zipcode": "95527",
		"entries": 0
	}
]

export default function Suggestions({suggestions, selectSuggestion}) {
	const suggestionList = suggestions;

	return <div className={"autocomplete--suggestions"} style={{minWidth: "max-content"}}>
		{suggestionList.map((suggestion, key) => <Suggestion
			key={key}
			suggestion={suggestion}
			selectSuggestion={() => selectSuggestion(suggestion)}
		/>)}
	</div>;
}