import React, { Component } from 'react';
//import { Button } from '@material-ui/core';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import { TextField, Table, TableCell, Modal, TableBody, Radio, TableHead, TableContainer,  TableRow, Button, Box, Tooltip, Paper, Toolbar, Typography, IconButton, Grid, Badge  } from '@material-ui/core';
import Loader from '../../../loader';
import { connect } from 'react-redux';
import axios from "axios";
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import i18n from '../../../../i18next';
import customStyle from '../../../../Assets/CSS/stylesheet_UHS';
import moment from "moment";
import DateFnsUtils from '@date-io/date-fns';
import Cart from '../../../../Assets/Images/cart.svg'
import {MuiPickersUtilsProvider, KeyboardDatePicker,DatePicker} from '@material-ui/pickers';
import CommonDropFamily from '../CommonPopup/CommonDropFamily';
import CommonDropDwn from '../CommonPopup/CommonDropDwn';
import CommonTable from './CommonTable'
import '../Add-ons/addOnsScreen.css';
import MultiAddons from './MultiAddons';


const useStylesBootstrap = makeStyles(theme => ({
  arrow: {
      color: '#fa6446',
  },
  tooltip: {
    backgroundColor: '#fa6446',
      border: '1px solid #dadde9',
      fontSize : '12px'
  },
}));

const useStylesBootstrap_otherQuote = makeStyles(theme => ({
    arrow: {
        color: '#4a4b57',
    },
    tooltip: {
        backgroundColor: '#4a4b57',
        border: '1px solid #dadde9',
        fontSize : '12px'
    },
}));


const StyledTableRow = withStyles({
    root: {
        backgroundColor: "#F4F4F4",
        '&.Mui-selected': {
            backgroundColor: "#08C289",
          },
          "&.Mui-selected.MuiTableRow-hover":{
            backgroundColor: "#08C289",
          },
    },
  })(TableRow);


const ProceedButton = withStyles(
    {
        root: {
            backgroundColor: '#0e234d',
            color: '#ffffff',
            fontWeight: "bold",
            '&:hover': {
                backgroundColor: '#420045',
                boxShadow: '0 4px 8px 0 #eae8db, 0 2px 4px 0 #eae8db',
            },
            '&:focus':{
                outline:'none'
            },
            '&:disabled': {
                backgroundColor: '#BDBDBD',
                color: '#ffffff'
            },
        },
    }

)(Button);

const styles = theme => ({
  table: {
    minWidth: 400,
  },
});




class AddonScreen extends Component {
  constructor(props) {
    super(props);
      this.state = {
        clientid: sessionStorage.getItem("CLIENT_ID"),
        subId:  JSON.parse(localStorage.getItem('CurrentLoginUser')).id,
        addonList:null,
        arrayOfAddonAmount: [],
        listofAddonSelected:[],
        addonSelectedFamily: [],
        selected: null,
        planSelectedAmount: null,
        data: null,
        selecdPlanID:null,
        appFeeAmount: null,
        loaderShow: false,
        coreSelectedFamily: null,
        isDisabled: false
    };
  }

  componentDidMount() {


    axios.get(process.env.REACT_APP_BASE_URL + "/enrollment/saveCompletionStatus/" + this.state.subId + "/" + this.props.activeStep)
    .then((response) => {
      if (response && response.data.code === 200) console.log(response.data.code)
    })
    .catch((error) => console.log(error));

    this.getCorePlanDetails();

    axios.get(process.env.REACT_APP_BASE_URL+"/plan/getAddonList"+`/${this.state.clientid}`).then((response)=>{
      this.setState({addonList: response.data.response})
    })
   }

   getCorePlanDetails = () =>{
    axios.get(process.env.REACT_APP_BASE_URL+"/plan/getMemberPlan"+`/${this.state.subId}/Core`).then((resp)=>{
      if(resp.data.response)
        this.setState({
          planSelectedAmount: resp.data.response.amount,
          appFeeAmount: resp.data.response.appFee,
          selecdPlanID: resp.data.response.planCode,
          coreSelectedFamily: resp.data.response.category
        })
        else
        this.props.backStep();
    })
  }

   setTotalAmount = (val, index) =>{
    let arrayOfAddonAmount = this.state.arrayOfAddonAmount;
    arrayOfAddonAmount[index]= val;
    this.setState({arrayOfAddonAmount: arrayOfAddonAmount})
   }
   setlistofAddonSelected=(val, index) =>{
    let listofAddonSelected =this.state.listofAddonSelected;
    listofAddonSelected[index]= val;
    this.setState({listofAddonSelected: listofAddonSelected})
   }
   setaddonSelectedFamily=(val, index) =>{
    let addonSelectedFamily =this.state.addonSelectedFamily;
    addonSelectedFamily[index]= val;
    this.setState({addonSelectedFamily: addonSelectedFamily})
   }
   SubmitPlanRequest = (e) =>{
    console.log("isDisabled", this.state.isDisabled);
    if(e.detail === 1){
        let submitArray = [];
        this.setState({loaderShow: true})
        this.state.listofAddonSelected.map((addonSelected, index) => {
    
            submitArray.push({
              subId: this.state.subId,
              planCode: this.state.isDisabled ? null : addonSelected ? addonSelected : null,
              amount: this.state.isDisabled ? 0 : this.state.arrayOfAddonAmount[index]? this.state.arrayOfAddonAmount[index]: 0,
              planType: this.state.addonList[index] ? this.state.addonList[index]: null,
              appFee: 0,
              category: this.state.addonSelectedFamily[index] ? this.state.addonSelectedFamily[index] : null,
            })
          
        })
          axios.post(process.env.REACT_APP_BASE_URL+"/plan/saveMemberPlanAddon", submitArray).then((res)=>{
        
                this.props.onClick();
               
  
          }).catch(err=>{
                console.log(err);
                this.setState({loaderShow: false})
            })
    }
  }
render() {

  var totalAmountOfAddon = 0;
  this.state.arrayOfAddonAmount.length> 0 && this.state.arrayOfAddonAmount.map(val=>{
    totalAmountOfAddon+=val?val:0;
  })
  if (this.state.isDisabled) totalAmountOfAddon = 0
  if(this.state.addonList)
    return (
      <div >
        {this.state.loaderShow && <Loader/>}
     <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
            <EnhancedTableToolbar
                                title={"Review the add-ons available, compare premiums and click the ADD button."}
                                planSelected={null}
                                planSelectedAmount={this.state.planSelectedAmount}
                                appFeeAmount={this.state.appFeeAmount}
                                arrayOfAddonAmount={this.state.arrayOfAddonAmount}
                                listofAddonSelected={this.state.listofAddonSelected}
                                costSelected={totalAmountOfAddon + this.state.planSelectedAmount+ this.state.appFeeAmount}
                                enrollFeeSelected={0}
                                reEnrollBySC={this.props.reEnrollBySC}
                                addonSelectedFamily={this.state.addonSelectedFamily}
                                coreSelecdPlanID={this.state.selecdPlanID}
                                coreSelectedFamily={this.state.coreSelectedFamily}
                                isDisabled={this.state.isDisabled}
                                />
        <div >
        <TableContainer style={{height: "38vh"}}>
        {this.state.addonList && this.state.addonList.map((val, index) =>(
              <MultiAddons 
                  addon={val} 
                  arrayOfAddonAmount={this.state.arrayOfAddonAmount}
                  listofAddonSelected={this.state.listofAddonSelected}
                  addonSelectedFamily={this.state.addonSelectedFamily}
                  setArrayOfAddonAmount={(val) => this.setTotalAmount(val, index)} 
                  setaddonSelectedFamily={(val) => this.setaddonSelectedFamily(val, index)}
                  setlistofAddonSelected={(val) => this.setlistofAddonSelected(val, index)}
                  addonList={this.state.addonList} 
                  isAddonDisabled={(e) => this.state.isDisabled = e}
                  indexOfMultiAddons={index} 
                  onClick={()=>this.props.onClick()}/>
        ))}
        </TableContainer>
        </div>
        </Paper>
        </Box>
            <div style={{paddingTop: 40, paddingLeft: 20, paddingBottom:20}}>
            <ProceedButton disabled={this.state.step === 0 || true} 
            onClick={() => this.backScreen()}>Back</ProceedButton>
      
             <ProceedButton style={{marginLeft: 20}} onClick={(e) => this.SubmitPlanRequest(e)}>Select add-ons & Proceed</ProceedButton>
         
            </div>
      </div>
    )
    else return <><Loader/></>
 
  }
}

const mapStateToProps = state => {
  return {
    subId: state.subId
  };
}

export default withStyles(styles)(connect(mapStateToProps)(AddonScreen));






const EnhancedTableToolbar = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    return (
      <>
        <Box>
          <Toolbar
            style={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              backgroundColor: "#162242",
            
              width: "100%",
              zIndex: 10,
            }}
          >
            <Grid row container spacing={1}>
              <Grid item xs={12} sm={12} md={9} lg={9}>
                <Typography
                  style={{ 
                   marginTop: 15,
                  color: "#fff" }}
                  component="div"
                >
                  {props.title}
                </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={2} lg={2}>
                  <IconButton>
                    <h3 style={{ color: "#00c389", margin: 0, fontStyle: "Lato", fontWeight: "700", marginLeft: 13 }}>
                    ${!props.reEnrollBySC ? Number(props.costSelected).toFixed(2):  Number(props.costSelected - props.appFeeAmount).toFixed(2)}
                    </h3>
                  </IconButton>
                </Grid>
                <Grid item xs={6} sm={6} md={1} lg={1}>
               
                  <IconButton onClick={(e) => props.isDisabled ? e.stopPropagation() : setModalShow(true)}>
                    <img src={Cart}/>
                    <div style={{marginLeft: 15, marginTop: "-7px"}}>
                    <Badge color="secondary" badgeContent={props.isDisabled ? 1 : 1 + props.arrayOfAddonAmount.filter(x => x !== 0).length}/>
                    </div>
                  </IconButton>
          
                </Grid>
            </Grid>
    
          </Toolbar>
        </Box>
        <ModalPopup
          show={modalShow}
          reEnrollBySC={props.reEnrollBySC}
          onHide={() => setModalShow(false)}
          planSelectedAmount = {props.planSelectedAmount}
          appFeeAmount={props.appFeeAmount}
          planSelected={props.planSelected ? props.planSelected :""}
          arrayOfAddonAmount={props.arrayOfAddonAmount}
          listofAddonSelected={props.listofAddonSelected}
          costSelected={props.costSelected}
          coreSelecdPlanID={props.coreSelecdPlanID}
          enrollFeeSelected={props.enrollFeeSelected}
          coreSelectedFamily={props.coreSelectedFamily}
          addonSelectedFamily={props.addonSelectedFamily}
        />
      </>
    );
  };

  const ModalPopup = (props) => {
    return (
      <>
        <Modal
          open={props.show}
          onClose={props.onHide}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className='ModalStyle'>
            <Box>
              {" "}
              <Toolbar
                style={{
                  backgroundColor: "#458586",
                  padding: 0,
                  paddingLeft: 20
                }}
                className="modal-header"
              >
                <Typography
                 style={{
                  flex: "1 1 100%",
                  fontFamily: "Lato",
                  fontSize: "20px",
                  fontWeight: "bold",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "1",
                  letterSpacing: "0.15px",
                  textAlign: "left",
                  color: "#ffff",
                  padding: 0
                }}
                  variant="b"
                  id="tableTitle"
                  component="div"
                >
                  Review Choices
                </Typography>
                <IconButton onClick={props.onHide}>
                  <CloseIcon style={{color:"#fff"}} />
                </IconButton>
              </Toolbar>
            </Box>
            <Box style={{padding: 20, paddingBottom:50, backgroundColor: "#fff"}}>
              <CommonTable
                reEnrollBySC={props.reEnrollBySC}
                planSelected={props.planSelected}
                planSelectedAmount={props.planSelectedAmount}
                arrayOfAddonAmount={props.arrayOfAddonAmount}
                listofAddonSelected={props.listofAddonSelected}
                appFeeAmount={props.appFeeAmount}
                costSelected={props.costSelected}
                enrollFeeSelected={props.enrollFeeSelected}
                coreSelecdPlanID={props.coreSelecdPlanID}
                coreSelectedFamily={props.coreSelectedFamily}
                addonSelectedFamily={props.addonSelectedFamily}
              />
            </Box>
          </Box>
        </Modal>
      </>
    );
  };