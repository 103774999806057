import React, { Component } from 'react'
import './Transaction.css'
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import moment from "moment";
import Button from '@material-ui/core/Button';
import { Modal } from 'react-bootstrap'
import customStyle from "./stylesheet_UHS";
import CustomeCss from './paymentmode.css.js';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import RadioChecked from './images/radioButtonChecked.png'
import RadioUnchecked from './images/radioButtonUnchecked.png';
import configurations from '../../../../configurations';
import axios from 'axios';
import CommonDropDwn from '../../../CommonScreens/CommonDropDwn';
import Sample from '../../../CommonScreens/sampleTextField';
import Loader from '../../../loader';

const AntTabs = withStyles(customStyle.tabs)(Tabs);

const AntTab = withStyles(theme => (customStyle.tab))(props => <Tab disableRipple {...props} />);

const NextButton = withStyles(customStyle.PayButton)(Button);

const currentYear = new Date().getFullYear();
const next10Years = Array.from({ length: 10 }, (_, index) => currentYear + index);
export default class ChangePayment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      disabled: true,
      tableData: [],
      open: false,
      activeTab: 1,
      accountTypes: ["CHECKING", "SAVINGS"],
      bankName: '',
      accountName: "",
      accountType: '',
      routingNo: '',
      accountNumber: '',
      cardNumber: '',
      holderName: "",
      expiryMonth: '',
      expiryYear: '',
      monthlyDebitDay: '',
      paymentType: '',
      cvv: '',
      selectedValue: '',
      years: next10Years,
      tabFirstIcon: RadioUnchecked,
      tabSecondIcon: RadioChecked,
      sourceCode: props.source,
      subId: props.subId,
      empid: null,
      disabled2: true,
      errorModal: false,
      MsgModal: '',
      accountNo: null,
      MsgModalerror: '',
      loader: true,
      notfoundmsg: '',
      errCodeCC: false,
      errCodeACH: false,
      MsgModalerrorFooterACH: '',
      MsgModalerrorFooterCC: '',
      reqFlag: true,
      customer3DSDtoList:[],
      countof3DS:2,
    }
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://cdn.3dsintegrator.com/threeds.2.2.20230227.min.js';
    document.body.appendChild(script);
    this.getAccountNumber()
    this.getPaymentDetails()
  }


  getAccountNumber = () => {
    axios.get(configurations.paymentBaseUrl + 'transaction/getLast4AccountNumber/' + this.state.sourceCode)
      .then(res => {
        // let AccountNo=res.data.response.replaceAll("*", "X");
        if (res.data.response !== null) {
          this.setState({ accountNo: res.data.response.replaceAll("*", "X"), loader: false })
        } else {
          this.setState({ accountNo: '' })
        }
      })
  }

  getPaymentDetails = () => {
    axios.get(
      configurations.baseUrl +
      "/setuppayment/getPaymentDetails/" +
      this.props.subId
    )
      .then((resp) => {
        let response = resp.data
        if (response.response) {
          this.getPaymentData(response.response.paymentType);
          let montlyDay = new Date(response.response.monthlyDebitDay).getDate();
          if (response.response.paymentType === "ACH") {
            this.setState({
              //achChecked:true,
              activeTab: 0,
            });
          } else if (response.response.paymentType === "CC") {
            this.setState({
              //ccChecked:true,
              activeTab: 1,
            });
          }

          this.setState(
            {
              bankName: response.response.bankName,
              accountName: response.response.accountName,
              accountType: response.response.accountType,
              routingNo: response.response.routingNo,
              accountNumber: response.response.accountNumber,
              cardNumber: response.response.cardNumber,
              holderName: response.response.holderName,
              expiryMonth: response.response.expiryMonth
                ? moment.monthsShort(
                  parseInt(response.response.expiryMonth) - 1
                )
                : "",
              expiryYear: response.response.expiryYear,
              monthlyDebitDay: montlyDay,
              cvv: response.response.cvv,
              paymentType: response.response.paymentType,
              preferedType: response.response.preferedType,
            },
            () => this.validateForm()
          );
        } else {
          let paymentType = "";
          if (this.state.activeTab === 0) {
            paymentType = "ACH";
          } else {
            paymentType = "CC";
          }
          this.getPaymentData(paymentType);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getPaymentData = (paymentType) => {
    let PAYMENT_ERROR = JSON.parse(localStorage.getItem("PAYMENT_ERROR"));
    axios.get(
      configurations.baseUrl +
      "/setuppayment/setupPaymentList/" +
      this.props.subId +
      "/" +
      paymentType
    )
      .then((resp) => {
        let response = resp.data
        this.setState({
          paymentData: response.response,
        });

        if (PAYMENT_ERROR) {
          if (PAYMENT_ERROR.responseCode === 203) {
            this.setState(PAYMENT_ERROR);
          } else if (PAYMENT_ERROR.responseCode === 500) {
            this.setState(PAYMENT_ERROR);
          }
        }

        this.setState(
          {
            loaderShow: false,
            disabled: false,
            disabled2: false,
            paymentData: response.response,
          },
          () => this.validateForm()
        );
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loaderShow: false,
        });
      });
  };

  handleClose = () => {
    this.setState({
      open: false,
      bankName: '',
      accountName: this.state.accountName,
      accountNumber: '',
      accountType: '',
      routingNo: '',
      cardNumber: '',
      holderName: this.state.holderName,
      expiryMonth: '',
      expiryYear: '',
      cvv: '',
      paymentType: '',
      MsgModalerror: '',
      errCodeACH: false,
      errCodeCC: false,
      MsgModalerror: '',
      MsgModalerrorFooterACH: '',
      MsgModalerrorFooterCC: ''
    }, () => {
      this.validateForm();
      this.props.closeModal()
    })
  };

  handleClickOpen = () => {
    this.setState({ open: true })
  };


  changeTextFieldHandler = (val, isValid, parentObj) => {
    if (parentObj.label === 'bank_name') {
      if (isValid) {
        this.state.bankName = val;
      } else {
        this.state.bankName = '';
      }
    } else if (parentObj.label === 'Account_Name') {
      if (isValid) {
        this.state.accountName = val;
      } else {
        this.state.accountName = '';
      }
    } else if (parentObj.label === 'Routing_Number') {
      if (isValid) {
        this.state.routingNo = val;
        this.state.errCodeACH = false
      } else {
        this.state.routingNo = '';
      }
    } else if (parentObj.label === 'Account_Number') {
      if (isValid) {
        this.state.accountNumber = val;
      } else {
        this.state.accountNumber = '';
      }
    } else if (parentObj.label === 'Account_Type') {
      if (isValid) {
        this.state.accountType = val;
      } else {
        this.state.accountType = '';
      }
    } else if (parentObj.label === 'Card_Number') {
      if (isValid) {
        this.state.cardNumber = val;
        this.state.MsgModalerror = ''
        this.state.errCodeCC = ''

      } else {
        this.state.cardNumber = '';
      }
    } else if (parentObj.label === 'Holder_Name') {
      if (isValid) {
        this.state.holderName = val;
      } else {
        this.state.holderName = '';
      }
    } else if (parentObj.label === 'expiryMonth') {
      if (isValid) {
        let currentDate = new Date();
        let month = currentDate.getMonth() + 1;
        let year = currentDate.getFullYear();
        let selectedMon = moment().month(val).format("M");
        if (this.state.expiryYear) {
          if (this.state.expiryYear === year && parseInt(selectedMon) < month) {
            this.state.validMonth = true;
            let evt = new CustomEvent('month', { detail: { flag: true } });
            window.dispatchEvent(evt);
          } else {
            this.state.validMonth = false;
            let evt = new CustomEvent('month', { detail: { flag: false } });
            window.dispatchEvent(evt);
          }
        }
        this.state.expiryMonth = val;
      } else {
        this.state.expiryMonth = '';
      }
    } else if (parentObj.label === 'expiryYear') {
      if (isValid) {
        let date = new Date();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let selectedMon = moment().month(this.state.expiryMonth).format("M");
        if (val === year && parseInt(selectedMon) < month) {
          this.state.validMonth = true;
          let evt = new CustomEvent('month', { detail: { flag: true } });
          window.dispatchEvent(evt);
        } else {
          this.state.validMonth = false;
          let evt = new CustomEvent('month', { detail: { flag: false } });
          window.dispatchEvent(evt);
        }
        this.state.expiryYear = val;
      } else {
        this.state.expiryYear = '';
      }
    } else if (parentObj.label === 'cvv') {
      if (isValid) {
        this.state.cvv = val;
      } else {
        this.state.cvv = '';
      }
    } else if (parentObj.label === 'monthlyDebitDay') {
      if (isValid) {
        this.state.monthlyDebitDay = val;
      } else {
        this.state.monthlyDebitDay = '';
      }
    }
    else if (parentObj.label === 'Street') {
      if (isValid) {
        this.state.userAddress.street = val;
      } else {
        this.state.userAddress.street = '';
      }
    }
    this.setState({
      refresh: true
    }, () => this.validateForm());
  };

  validateForm() {
    if (this.state.activeTab === 0) {
      if (this.state.bankName !== '' && this.state.accountName !== '' && this.state.accountNumber !== '' && this.state.accountType !== '' && this.state.routingNo !== '') {
        this.setState({
          disabled: false
        }, () => console.log("====Button===" + this.state.disabled));
      } else {
        this.setState({
          disabled: true
        }, () => console.log("====Button===" + this.state.disabled));
      }
    } else if (this.state.activeTab === 1) {
      if (this.state.cardNumber !== '' && this.state.holderName !== '' && this.state.expiryMonth !== '' && this.state.expiryYear !== '' && this.state.cvv !== '' && !this.state.validMonth) {
        this.setState({
          disabled2: false
        });
      } else {
        this.setState({
          disabled2: true
        });
      }
    }
  }

  handleChange = (event, newValue) => {
    console.log("====================New Value" + newValue)
    this.setState({
      loaderShow: true,
      refresh: true
    });
    let flag;
    if (newValue === 0) {
      this.setState({ activeTab: 0, errCodeACH: this.state.errCodeACH })
      this.setState({ tabFirstIcon: RadioChecked })
      this.setState({ tabSecondIcon: RadioUnchecked })
    } else {
      this.setState({ activeTab: 1 })
      this.setState({ tabFirstIcon: RadioUnchecked })
      this.setState({ tabSecondIcon: RadioChecked })
    }
  }

  handleChangeRadio = (event) => {
    this.setState({ selectedValue: event.target.value })
  }
  get3DSResponse = async (amount) => {
    const promises = [];
    try {
      var payment3Ds = [];
      for (let index = 0; index < this.state.countof3DS; index++) {
        const data = await this.threeDSScriptLoad(amount);
        if(data && data.status === "Y" || data.status === "I" || data.status === "A"){
          promises.push(data);
        }else{
          break;
        }
      }
    } catch (error) {
      console.log(error);
      return error;
    }
      const results = await Promise.all(promises);
      payment3Ds = results.map(result => {
        return result;
      });
      return payment3Ds;
  };
  threeDSScriptLoad = async (amt, timeoutDuration = 15000) => {
    let responseData;
    const tds = new window.ThreeDS(
      "billing-form",
      process.env.REACT_APP_3DS_TOKEN,
      null,
      {
        endpoint: process.env.REACT_APP_3DS_API,
        verbose: true,
        showChallenge: true,
      }
    );
  
    try {
       await Promise.race([
        new Promise((resolve, reject) => {
          tds.verify(
            response => {
              responseData = response;
              resolve(); // Resolve the promise once the response is received
            },
            error => {
              console.log("Verify method Reject function called. Error:", error);
              reject(error); // Reject the promise in case of an error
            },
            { amount: Number(amt) * 1.1 },
            null,
            null,
            rebill_resolve => console.log("Verify method Rebill Resolve function called. Response:", rebill_resolve),
            rebill_reject => console.log("Verify method Rebill Reject function called. Error:", rebill_reject)
          );
        }),
        new Promise((resolve, reject) => {
          setTimeout(() => {
            reject(new Error("Verification process timed out")); 
          }, timeoutDuration);
        })
      ]);
    } catch (error) {
      if (error && error.status === 404) {
        // Handle 404 error here
        console.error("404 Error: Resource not found");
        // You can add further actions or throw this error if needed
      } else {
        // Handle other errors including timeout
        console.error("Error occurred:", error);
        throw error;
      }
    }
  
    return responseData;
  };
  changePaymentMode = () => {
    this.setState({ loader: true, MsgModalerror: '' })
    let data
    var payType = ''
    if (this.state.activeTab == 0) {
      payType = "ACH"
    } else {
      payType = "CC"
    }
    let totalCost =
      this.state.paymentData[this.state.paymentData.length - 1].amount;
    let date =
      moment(new Date().setMonth(new Date().getMonth() + 1)).format("MM") +
      "/" +
      moment(new Date().setDate(this.state.monthlyDebitDay)).format("DD") +
      "/" +
      moment(new Date()).format("YYYY");
    let amt = totalCost.split("$");
    data = {
      "subId": this.props.subId,
      "source": this.state.sourceCode,
      "bankName": this.state.bankName,
      "accountName": this.state.accountName,
      "accountNumber": this.state.accountNumber,
      "accountType": this.state.accountType,
      "routingNo": this.state.routingNo,
      "cardNumber": this.state.cardNumber,
      "holderName": this.state.holderName,
      "expiryMonth": this.state.expiryMonth ? moment().month(this.state.expiryMonth).format("M") : "",
      "expiryYear": this.state.expiryYear,
      "cvv": this.state.cvv,
      "paymentType": payType,
      "preferedType": this.state.preferedType,
      "monthlyDebitDay": moment(date).format("YYYY-MM-DD"),
      "amount": amt[1],
    }
    this.props.toggleLoader(true);
    axios
      .post(
        configurations.baseUrl + "/setuppayment/storeTransaction",
        data
      )
      .then(async (res) => {
        console.log(
          "=============== storeTransaction =================="
        );
        console.log(res);
        if (res.data.code === 200) {
          const threeDSResponse = await this.get3DSResponse(amt[1]);
          this.setState({customer3DSDtoList: threeDSResponse[0].acsTransId? threeDSResponse: null})
          data.customer3DSDtoList = threeDSResponse[0].acsTransId? threeDSResponse: null;
          axios
            .post(
              configurations.baseUrl +
              "/setuppayment/savePaymentDetails",
              data
            )
            .then((response) => {
              if (res.data.code === 200) {
                this.props.toggleLoader(false);
                this.setState({
                  storedTranModal: false,
                  // waitingRes: "",
                  loaderShow: false,
                  loader: false
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
          this.setState({ errorModal: true, open: false, loader: false, MsgModal: 'The payment details have been updated successfully.' })
          this.props.closeModal();
          this.props.toggleAlertBox('The payment details have been updated successfully.', "close")
        }
        else if (res.data.code == 202) {
          let x = JSON.parse(res.data.response).error_message;
          let cds = x.split(' - ');
          this.setState({ MsgModalerror: x, loader: false, errCodeACH: true })
          this.setState({ errorModal: true, open: false, loader: false, MsgModal: x })
          this.props.toggleAlertBox(x, true)
          if (this.state.activeTab == 0 && this.state.MsgModalerror != '') {
            this.setState({
              errCodeACH: true,
              cvv: '',
              cardNumber: '',
              errCodeCC: false,
              expiryMonth: '',
              expiryYear: '',
              reqFlag: false,
            })
            let evt = new CustomEvent('errorCode', { detail: { flag: true } });
            window.dispatchEvent(evt);
          }
          if (this.state.activeTab == 1) {
            this.setState({
              errCodeCC: true,
              bankName: '',
              accountNumber: '',
              accountType: '',
              routingNo: '',
              errCodeACH: false,
            })
            let evt = new CustomEvent('errorCode', { detail: { flag: true } });
            window.dispatchEvent(evt);
          }
          this.props.toggleLoader(false);
        }
        else if (res.data.code == 204) {
          if (this.state.activeTab == 0) {
            this.setState({
              MsgModalerrorFooterACH: 'Source is not registered',
              loader: false,
              MsgModalerrorFooterCC: '',
              cvv: '',
              cardNumber: '',
              errCodeCC: false,
              expiryMonth: '',
              expiryYear: '',
            })
          } else {
            this.setState({
              MsgModalerrorFooterCC: 'Source is not registered',
              loader: false,
              MsgModalerrorFooterACH: '',
              bankName: '',
              accountNumber: '',
              accountType: '',
              routingNo: '',
            })
          }
          this.props.toggleLoader(false);
        }
        else if (res.data.code == 500) {
          if (this.state.activeTab == 0) {
            this.setState({
              MsgModalerrorFooterACH: 'There was a system error when updating the payment method. Please try again later.',
              loader: false, MsgModalerrorFooterCC: '',
              cvv: '',
              cardNumber: '',
              errCodeCC: false,
              expiryMonth: '',
              expiryYear: '',
            })
          } else {
            this.setState({
              MsgModalerrorFooterCC: 'There was a system error when updating the payment method. Please try again later.',
              loader: false, MsgModalerrorFooterACH: '',
              bankName: '',
              accountNumber: '',
              accountType: '',
              routingNo: '',
            })
          }
          this.props.toggleLoader(false);
        }
        else {
          let x = JSON.parse(res.data.response).error_message;
          let cds = x.split(' - ');
          if (this.state.activeTab == 0) {
            this.setState({
              MsgModalerrorFooterACH: cds[1], loader: false,
              cvv: '',
              cardNumber: '',
              errCodeCC: false,
              expiryMonth: '',
              expiryYear: '',
            })
          } else {
            this.setState({
              MsgModalerrorFooterCC: cds[1], loader: false,
              bankName: '',
              accountNumber: '',
              accountType: '',
              routingNo: '',
            })
          }
          this.props.toggleLoader(false);
        }
      }).catch((err) => {
        this.props.toggleLoader(false);
      })
  }

  handleCloseErrorModal = () => {
    this.getAccountNumber()
    this.setState({
      errorModal: false,
      bankName: '',
      accountName: this.state.accountName,
      accountNumber: '',
      accountType: '',
      routingNo: '',
      cardNumber: '',
      holderName: this.state.holderName,
      expiryMonth: '',
      expiryYear: '',
      cvv: '',
      paymentType: '',
      MsgModalerror: '',
      errCodeACH: false,
      errCodeCC: false
    })
  }

  goBack = () => {
    this.props.history.push('/')
  }
  render() {
    const { classes } = this.props
    let currentScreen = '';
    if (this.state.activeTab === 0) {
      currentScreen = <div >
        <Grid container spacing={1} style={{ marginTop: '1%' }}>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} style={{ marginBottom: '-1%' }}>
                <Sample setChild={this.changeTextFieldHandler.bind(this)} name={'Bank_Name'} reqFlag={true} label={'Bank Name'} value={this.state.bankName} disable={false} style={CustomeCss.textField} length={120} fieldType={'bank_name'} errMsg={'Enter valid bank name'} helperMsg={'Bank name required'} parentDetails={{ label: 'bank_name' }} key={0}></Sample>
              </Grid>
              <Grid item xs={12} sm={4} style={{ marginBottom: '-1%' }}>
                <Sample setChild={this.changeTextFieldHandler.bind(this)} name={'Account_Name'} reqFlag={true} label={'Name on Account'} value={this.state.accountName} disable={false} style={CustomeCss.textField} length={120} fieldType={'accountName'} errMsg={'Enter valid account name'} helperMsg={'Name on account required'} parentDetails={{ label: 'Account_Name' }} key={0}></Sample>
              </Grid>
              <Grid item xs={12} sm={4} style={{ marginBottom: '-1%' }}>
                <CommonDropDwn setChild={this.changeTextFieldHandler.bind(this)} name={'AccountType'} label={'Account Type'} value={this.state.accountType} disable={false} style={customStyle.dropDown} fieldType={'dropDwn'} helperMsg={'Select account type'} List={this.state.accountTypes} parentDetails={{ label: 'Account_Type' }}></CommonDropDwn>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: '1.5%' }}>
              <Grid item xs={12} sm={6} style={{ marginBottom: '-2%' }} >
                {
                  this.state.MsgModalerror != '' ?
                    <Sample setChild={this.changeTextFieldHandler.bind(this)} name={'Routing_Number'} MsgModalerror={this.state.MsgModalerror} ErrCode={this.state.errCodeACH} reqFlag={true} label={'Routing Number'} value={this.state.routingNo} disable={false} style={CustomeCss.textField} length={9} fieldType={'routingNo'} errMsg={'Enter valid routing number'} helperMsg={this.state.MsgModalerror} parentDetails={{ label: 'Routing_Number' }} key={0}></Sample>
                    :
                    <Sample setChild={this.changeTextFieldHandler.bind(this)} name={'Routing_Number'} MsgModalerror={this.state.MsgModalerror} ErrCode={this.state.errCodeACH} reqFlag={true} label={'Routing Number'} value={this.state.routingNo} disable={false} style={CustomeCss.textField} length={9} fieldType={'routingNo'} errMsg={'Enter valid routing number'} helperMsg={'Routing number required'} parentDetails={{ label: 'Routing_Number' }} key={0}></Sample>
                }
              </Grid>
              <Grid item xs={12} sm={6}>
                <Sample setChild={this.changeTextFieldHandler.bind(this)} name={'Account_Number'} reqFlag={true} label={'Account Number'} value={this.state.accountNumber} disable={false} style={CustomeCss.textField} length={26} fieldType={'accountNumber'} errMsg={'Account number Should be minimum of 4 digit'} helperMsg={'Account number required'} parentDetails={{ label: 'Account_Number' }} key={0}></Sample>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    } else if (this.state.activeTab === 1) {
      currentScreen = <div >
        <Grid container spacing={1} style={{ marginTop: '1%' }}>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={3} >
              <Grid item xs={12} sm={6} style={{ marginBottom: '-3%' }}>
                {
                  this.state.MsgModalerror != '' ?
                    <Sample setChild={this.changeTextFieldHandler.bind(this)} name={'Card_Number'} MsgModalerror={this.state.MsgModalerror} ErrCode={this.state.errCodeCC} reqFlag={this.state.reqFlag} label={'Card Number'} value={this.state.cardNumber} disable={false} style={CustomeCss.textField} length={16} fieldType={'cardNumber'} errMsg={'Card number up to 15 or 16 digits'} helperMsg={this.state.MsgModalerror} parentDetails={{ label: 'Card_Number' }}></Sample>
                    :
                    <Sample setChild={this.changeTextFieldHandler.bind(this)} name={'Card_Number'} MsgModalerror={this.state.MsgModalerror} ErrCode={this.state.errCodeCC} reqFlag={this.state.reqFlag} label={'Card Number'} value={this.state.cardNumber} disable={false} style={CustomeCss.textField} length={16} fieldType={'cardNumber'} errMsg={'Card number up to 15 or 16 digits'} helperMsg={'Card number required'} parentDetails={{ label: 'Card_Number' }}></Sample>
                }
              </Grid>
              <Grid item xs={12} sm={6} >
                <Sample setChild={this.changeTextFieldHandler.bind(this)} name={'Holder_Name'} reqFlag={true} label={'Card Holder Name'} value={this.state.holderName} disable={false} style={CustomeCss.textField} length={25} fieldType={'holderName'} errMsg={'Enter valid card holder name'} helperMsg={'Card holder name required'} parentDetails={{ label: 'Holder_Name' }}></Sample>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: '2%' }}>
              <Grid item xs={12} sm={4}>
                <CommonDropDwn setChild={this.changeTextFieldHandler.bind(this)} name={'expiryMonth'} label={'Expiration Month'} value={this.state.expiryMonth} disable={false} style={customStyle.dropDown} fieldType={'dropDwn'} helperMsg={'Select expiration month'} errMsg={"The expiration date is before today's date. Enter valid expiration month"} List={this.state.months} parentDetails={{ label: 'expiryMonth', val: this.state.expiryYear }} key={1}></CommonDropDwn>
              </Grid>
              <Grid item xs={12} sm={4}>
                <CommonDropDwn setChild={this.changeTextFieldHandler.bind(this)} name={'expiryYear'} label={'Expiration Year'} value={this.state.expiryYear} disable={false} style={customStyle.dropDown} fieldType={'dropDwn'} helperMsg={'Select expiration year'} errMsg={"The expiration date is before today's date. Enter valid expiration year"} List={this.state.years} parentDetails={{ label: 'expiryYear', val: this.state.expiryMonth }} key={1}></CommonDropDwn>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Sample setChild={this.changeTextFieldHandler.bind(this)} name={'cvv'} label={'CVV'} reqFlag={true} value={this.state.cvv} disable={false} style={CustomeCss.textField} length={4} fieldType={'cvv'} errMsg={'Enter valid CVV'} helperMsg={'CVV required'} parentDetails={{ label: 'cvv' }} key={1}></Sample>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <form id="billing-form" style={{display:"none"}}>
                  <input type="text" name="pan" value={this.state.cardNumber} data-threeds="pan" />
                  <input type="text" name="month" value={moment().month(this.state.expiryMonth).format("M")} data-threeds="month"/>
                  <input type="text" name="year" value={this.state.expiryYear} data-threeds="year"/>
          </form>
      </div>
    }
    return (
      <Modal size="lg" show={this.props.open} onHide={this.handleClose} centered className="ChangPayModal" backdrop='static'>
        <Modal.Body>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
          >
            <span class="Change-Payment-Method">Update Payment Info</span>
            <IconButton aria-label="close" onClick={this.handleClose} style={{ marginTop: '-13px' }}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={12} >
            </Grid>
          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={12} >
              <AntTabs
                value={this.state.activeTab}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <AntTab label="ACH DEBIT" disabled={true} className='ant-col-15 tabBtn tabFirst' icon={<img className="raidoBtn" src={this.state.tabFirstIcon} />} />
                <AntTab label="CREDIT CARD" className='ant-col-15 tabBtn tabSecond' icon={<img className="raidoBtn" src={this.state.tabSecondIcon} />} />
              </AntTabs>
            </Grid>
          </Grid>
          <div >
            {/* <span>  Account Number currently on file XXXXXX4567</span> */}
          </div>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-end"
          >
            <Grid item xs={12} sm={3} md={4} >
              {/* <table className="Last_4_digit" >
                <td className="td_style1">Account Number currently on file</td>
                <td className="td_style" style={{ verticalAlign: 'middle' }}>
                  {this.state.accountNo}
                </td>
              </table> */}
            </Grid>
          </Grid>
          {
            this.state.loader ? <Loader /> : ''
          }
          {currentScreen}
        </Modal.Body>
        <Modal.Footer>
          {this.state.activeTab == 0 ?
            <>
              <p className="Footer-error-msg" style={{}}>{this.state.MsgModalerrorFooterACH}</p>
              <NextButton color="primary" variant="contained" onClick={this.handleClose} style={{ marginRight: '15px' }} class="ButtonBG">
                CANCEL
              </NextButton>
              <NextButton variant="contained" disabled={this.state.disabled} color="primary" onClick={this.changePaymentMode} >
                DONE
              </NextButton>
            </>
            :
            <>
              <p className="Footer-error-msg" style={{}}>{this.state.MsgModalerrorFooterCC}</p>
              <NextButton color="primary" variant="contained" onClick={this.handleClose} style={{ marginRight: '15px' }} class="ButtonBG">
                CANCEL
              </NextButton>
              <NextButton variant="contained" disabled={this.state.disabled2} color="primary" onClick={this.changePaymentMode} >
                DONE
              </NextButton>
            </>
          }
        </Modal.Footer>
      </Modal>
    )
  }
}
