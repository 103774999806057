import React ,{Component} from 'react';
import Loader from "../../loader";
import Grid from '@material-ui/core/Grid';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import customStyle from "../../../Assets/CSS/stylesheet_UHS";
import Button from "@material-ui/core/Button";
import customeClasses from "./Eligibility.css";
import CommonTable from "../../CommonScreens/commonTable";
import axios from 'axios';
import configurations from "../../../configurations";
import {connect} from "react-redux";
import {Modal} from "react-bootstrap";
import { Table, TableCell,Paper, TableBody, TableHead, TableRow, Tooltip } from '@material-ui/core';
import "./Add-ons/addOnsScreen.css"

const StyledTableCell = withStyles(theme => (customStyle.tableCell))(TableCell);
const StyledTableRow = withStyles(theme => (customStyle.tableRow))(TableRow);
const ACSMCheckbox = withStyles({
    root: {
        color: '#533278',
        "&$checked": {
            color: '#533278',
        },
        "&$disabled": {
            color: 'grey'
        }
    },
    checked: {},
    disabled: {
        color: 'grey'
    }
})(Checkbox);

const styles = props => (
    customStyle.chkEligiScreen
);

// const WizardButton = withStyles(
//     customStyle.viewBtn
// )(Button);

// const ProceedButton = withStyles(
//     customStyle.proceedBtn
// )(Button);
// const ViewButton = withStyles(
//     customStyle.viewBtn
// )(Button);
const WizardButton = withStyles(
    customStyle.viewNetwellBtn
)(Button);

const ProceedButton = withStyles(
    customStyle.proceedNetwellBtn
)(Button);
const ViewButton = withStyles(
    customStyle.viewNetwellBtn
)(Button);

const NextButton = withStyles(
    customStyle.NextButton
)(Button);


class ViewQuote extends Component{
    constructor(props) {
        super(props);
        this.state = {
            headerData : [],
            addOnsBodyData : [],
            loaderShow : true,
            progress : 0,
            count : 0,
            tableData : null,
            healthTool : false,
            membersArr : [],
            popTable : null,
            moreInfoModal : false,
            check:false,
            subID: JSON.parse(localStorage.getItem('CurrentLoginUser')).id,
        }
    }

    componentDidMount=()=> {
        
        let subId = JSON.parse(localStorage.getItem('CurrentLoginUser')).id;
        axios.get(process.env.REACT_APP_BASE_URL + "/enrollment/saveCompletionStatus/" + subId + "/" + this.props.activeStep)
        .then((response) => {
          if (response && response.data.code === 200) console.log(response.data.code)
        })
        .catch((error) => console.log(error));
        if(this.props.reEnrollBySC){
            axios.get(configurations.baseUrl + '/plan/reenrollviewQuote/' + this.state.subID + '/plan')
            .then(response =>{
                 this.setState({
                    tableData: response.data.response,
                    loaderShow:false
                 },()=>{
                     
           
                })
            })
            .catch(error => {
                console.log(error);
                this.setState({
                  loaderShow: false,
                //   showCommonErrorModal: true,
                });  
              })
        }else{
        axios.get(configurations.baseUrl + '/plan/viewQuote/' + this.state.subID + '/plan')
            .then(response =>{
                console.log("::::", response.data.response)
                 this.setState({
                    tableData: response.data.response,
                    loaderShow:false
                 },()=>{
                     
           
                })
            })
            .catch(error => {
                console.log(error);
                this.setState({
                  loaderShow: false,
                //   showCommonErrorModal: true,
                });  
              })
        }
    }

    handleBack = () =>{
        this.props.backClick();
    }

    handleProceed = (e) =>{
        if (e.detail === 1)
        this.props.onClick();
    }
    render() {
        const { classes } = this.props;
        let currentScreen
             currentScreen = <Grid container spacing={2}>
                <Grid item xs={12} sm={12} >
                    <Grid item xs={12} sm={12}>
                    <div style={{textAlign : 'justify', width : '95%'}}>
                    If you want to make changes to your selections, click on the BACK button. To continue with the selection click PROCEED.
                    </div>

                    </Grid>
                         
                </Grid>

                {this.props.reEnrollBySC ? 
                <>
                <Grid item xs={6} sm={6} style={{paddingRight:30}}>
                    <div className="addOnTable reviewChoice" style={{width : '100%', overflowX : 'auto'}}>
                        {this.state.tableData && <CommonTable reEnrollBySC={this.props.reEnrollBySC} quoteData={this.state.tableData.body["Current Payment"]} check={true} headerData={this.state.tableData.header["Current Payment"]} tooltip={[]} quickQuote={false} totalReq={true} />}
                    </div>
                </Grid>
                <Grid item xs={6} sm={6}>
                    <div className="addOnTable reviewChoice" style={{width : '100%', overflowX : 'auto'}}>
                        {this.state.tableData && <CommonTable reEnrollBySC={this.props.reEnrollBySC} quoteData={this.state.tableData.body["New Payment"]} check={true} headerData={this.state.tableData.header["New Payment"]} tooltip={[]} quickQuote={false} totalReq={true} />}
                    </div>
                </Grid>
                </>
               :<Grid item xs={12} sm={12}>

                    <div className="addOnTable reviewChoice" style={{width : '100%', overflowX : 'auto'}}>
                    {       
                        this.state.tableData && <CommonTable quoteData={this.state.tableData.body} check={true} headerData={this.state.tableData.header} tooltip={[]} quickQuote={false} totalReq={true} />
                    }
                    </div>
                  
                    </Grid> }
                </Grid>

        

        return (
            <div style={{flexGrow: 1}}>
                {
                    this.state.loaderShow ? <Loader></Loader> : ''
                }
                <Grid container style={{ fontFamily : 'Roboto, Arial, Helvetica, sans-serif', fontSize : '14px'}}>
                   

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        {currentScreen}
                    </Grid>
                    <Grid item xs={12} sm={10} md={6} style={{marginTop : '25px'}}>
                        <Grid container spacing={3}>
                            <Grid item xs={4} sm={2}>
                                <WizardButton
                                            //   disabled={true}
                                              variant="contained" style={{width : '100%'}}
                                              onClick={this.handleBack}>BACK
                                </WizardButton>
                            </Grid>

                            <Grid item xs={4} sm={3}>
                                <ProceedButton disabled={false}
                                               variant="contained" style={{width : '100%'}}
                                               onClick={this.handleProceed}>PROCEED
                                </ProceedButton>
                                {/*style={{width: '104px', height: '40px'}}*/}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

           

            </div>
        );
    }
    handleAddOn(){
        if(!this.state.check)
        this.setState({check:true})
        else
        this.setState({check:false})
    }
}

const mapStateToProps = state => {
    return {
        subId: state.subId,
        email : state.email
    };
}


export default withStyles(styles)(connect(mapStateToProps)(ViewQuote));
