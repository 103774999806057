import * as React from "react";
import {Select, MenuItem, FormControl} from '@material-ui/core';




export default function CommonDropFamily(props) {
  const [value, setValue] = React.useState("");
  const [list] = React.useState(props.value);
  const handleChange = (event) => {
    setValue(event.target.value);
    props.selectCost(event.target.value, props.index);
  };

  React.useEffect(() => {
    if(props.selectedIndex)
    setValue(props.selectedFamily);
    else
    setValue(list[0].category);
  }, []);
  
  return (
    <FormControl style={{ m: 1, width: 200, margin:10 }} size="small">
      <Select
        value={props.selectedFamily}
        style={{ backgroundColor: "#fff", padding:5, color: "#787885" }}
        onChange={(e) => handleChange(e)}
        disabled={props.disabled}
      >
        {list.map((row, index) => (
          <MenuItem value={row.category}>{row.category}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
