import React, { Component }  from 'react';
import { withRouter } from 'react-router';

class Footer extends Component {
  
  render() {
    return (
      <div>    
        
                    
      <p style={{textAlign:'center'}}>Copyright © 2023 Innovative Partners. All rights reserved.</p>
                  
      </div>

    )
  }


}
export default withRouter(Footer);