import React from "react";

import { TextField, Table, TableCell, Modal, TableBody, Radio, TableHead, TableContainer,  TableRow, Button, Box, Tooltip, Paper, Toolbar, Typography, IconButton  } from '@material-ui/core';


const CommonTable = (props) => {
  console.log("::::", props)
  return ( 
    <>
      <TableContainer component={Paper}>
        <Table  sx={{ minWidth: "100%", fontWeight: "Lato" }} aria-label="customized table">
          <TableHead>
            <TableRow style={{ backgroundColor: "#0e234d"}}>
              <TableCell style={style}><b style={{color: "#fff"}}>Plan Fees</b></TableCell>
              {!props.reEnrollBySC && <TableCell style={style} align="left">
              <b style={{color: "#fff"}}>Here's your first payment</b>
              </TableCell>}
              <TableCell style={style} align="left">
              {props.reEnrollBySC ?<b style={{color: "#fff"}}>Your new payment</b> :<b style={{color: "#fff"}}>Here's your subsequent <br />
                monthly payment</b>}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow style={{ borderBottom : '2px solid #420045',}}>
              <TableCell style={style} component="th" scope="row">
              <p style={{color: "#000", marginBottom: 0}}>Monthly Payment for <b>{props.coreSelecdPlanID}</b> ({props.coreSelectedFamily})</p>
              </TableCell>
              {!props.reEnrollBySC && <TableCell style={style} component="th" scope="row" align="left">
              <p style={{color: "#000", marginBottom: 0}}>${Number(props.planSelectedAmount).toFixed(2)}</p>
              </TableCell>}
              <TableCell style={style} align="left">
              <p style={{color: "#000", marginBottom: 0}}>${Number(props.planSelectedAmount).toFixed(2)}</p>
              </TableCell>
            </TableRow>

            {!props.reEnrollBySC && <TableRow style={{ borderBottom : '2px solid #420045',}}>
              <TableCell style={style} component="th" scope="row">
              <p style={{color: "#000", marginBottom: 0}}>One Time Enrollment Fee</p>
              </TableCell>
              <TableCell style={style} component="th" scope="row" align="left">
              <p style={{color: "#000", marginBottom: 0}}>${Number(props.appFeeAmount).toFixed(2)}</p>
              </TableCell>
              <TableCell style={style} align="left">-</TableCell>
            </TableRow>}
           

            {props.listofAddonSelected.length>0 && props.listofAddonSelected.map((value, index)=>
              value && <TableRow style={{ borderBottom : '2px solid #420045',}}>
              <TableCell style={style} component="th" scope="row">
              <p style={{color: "#000", marginBottom: 0}}>Monthly Payment for <b>{value}</b> ({props.addonSelectedFamily[index]})</p>
              </TableCell>
             {!props.reEnrollBySC &&  <TableCell style={style} component="th" scope="row" align="left">
              <p style={{color: "#000", marginBottom: 0}}>${Number(props.arrayOfAddonAmount[index]).toFixed(2)}</p>
              </TableCell>}
              <TableCell style={style} align="left"><p style={{color: "#000", marginBottom: 0}}>${Number(props.arrayOfAddonAmount[index]).toFixed(2)}</p></TableCell>
            </TableRow>
            )}

            

            <TableRow style={{background: "#00c389",  borderBottom : '2px solid #420045',}}>
              <TableCell style={style}><b style={{color: "#fff"}}>Family Total</b></TableCell>
              {!props.reEnrollBySC && <TableCell style={style} align="left">
              <b style={{color: "#fff"}}>${Number(props.costSelected + props.enrollFeeSelected).toFixed(2)}</b>
              </TableCell>}
              <TableCell style={style} align="left">
              <b style={{color: "#fff"}}>${Number(props.costSelected-props.appFeeAmount).toFixed(2)}</b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default CommonTable;

const style={
  padding: 11
}